import { Controller } from "@hotwired/stimulus"
import 'jquery-datetimepicker'

// Connects to data-controller="datetimepicker"
export default class extends Controller {
  static targets = ["input"]

  connect() {
    $(this.inputTargets).datetimepicker({
      format: 'Y-m-d H:i',
    })
  }
}
