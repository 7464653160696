import { Controller } from "@hotwired/stimulus"
import "../../vendor/jquery-ui.min"

// Connects to data-controller="survey--eq5d-vas"
export default class extends Controller {
  static targets = ["slider", "input", "value"]
  static values = {
    min: { type: Number, default: 0 },
    max: { type: Number, default: 100 },
    granularity: { type: Number, default: 1 },
    value: Number
  }

  connect() {
    $(this.sliderTarget).slider({
      orientation: "vertical",
      range: "min",
      min: this.minValue,
      max: this.maxValue,
      step: this.granularityValue,
      value: this.valueValue,
      slide: (event, ui) => {
        $(this.inputTarget).val(ui.value);
        $(this.valueTarget).text(ui.value);
      }
    })
  }
}
