import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="survey--checkbox"
export default class extends Controller {
  static targets = ["choice", "subquestion"]

  connect() {
    const subQuestionElements = document.querySelectorAll('.sub-question')
    subQuestionElements.forEach((element) => {
      const id = element.getAttribute('id')
      const choiceId = id.replace('sub_', '')
      const choice = document.getElementById(choiceId)
      if (choice.checked !== true) {
        this.hide(element)
      }
    })
  }

  toggle_sub_question(event) {
    const element = event.target
    const callerType = element.getAttribute('type')
    const callerChecked = element.checked
    const callerId = element.getAttribute('id')
    const subQuestion = document.getElementById('sub_' + callerId)

    if (callerType === 'checkbox') {
      if (callerChecked) {
        this.show(subQuestion)
      } else {
        this.hide(subQuestion)
      }
    }
  }

  hide(element) {
    if (element) {
      element.classList.add('d-none')
      const path = '#' + element.getAttribute('id') + ' > div > div > fieldset input.save'
      const flag = document.querySelector(path)
      flag.setAttribute('value', '')
    }
  }

  show(element) {
    if (element) {
      element.classList.remove('d-none')
      const path = '#' + element.getAttribute('id') + ' > div > div > fieldset input.save'
      const flag = document.querySelector(path)
      flag.setAttribute('value', '1')
    }
  }

  toggle_exclusive(event) {
    const element = event.target
    const callerName = element.getAttribute('name')
    const callerId = element.getAttribute('id')
    const callerExclusive = element.classList.contains('exclusive-choice')

    const inputElements = document.querySelectorAll("input[name='" + callerName + "']")

    inputElements.forEach((element) => {
      const inputFieldId = element.getAttribute('id')
      const inputExclusive = element.classList.contains('exclusive-choice')
      const elementInterface = document.querySelectorAll('label[for=' + inputFieldId + ']')

      if (inputFieldId !== callerId) {
        // we are not the caller input element so we can modify checked state
        // if the selected choice is exclusive deselect everything else
        // or the selected choice is not exclusive deselect any eclusive options
        if (callerExclusive || (!callerExclusive && inputExclusive)) {
          // update the state of the span that the user sees and the hidden input
          // field
          elementInterface.forEach((e) => {
            this.uncheck(e)
          })
          this.uncheck(element)
          if (element.classList.contains('expands')) {
            const subQuestion = document.getElementById('sub_' + inputFieldId)
            this.hide(subQuestion)
          }
        }
      }
    })
  }

  uncheck(element) {
    if (element.classList.contains('checked')) {
      element.classList.remove('checked')
    }
    if (element.checked) {
      element.checked = false
    }
  }
}
