import { Controller } from "@hotwired/stimulus"
import "../../vendor/jquery-ui.min"

// Connects to data-controller="survey--autocomplete"
export default class extends Controller {
  static targets = ["input"]
  static values = {
    type: String,
    url: String,
    source: Array
  }

  connect() {
    if (this.typeValue == "Array") {
      this.initialize_autocomplete(this.sourceValue)
    } else {
      $.getJSON(this.urlValue, (data) => { this.initialize_autocomplete(data) })
    }
  }

  initialize_autocomplete(source) {
    $(this.inputTarget).autocomplete({
      source: source,
      autoFocus: true
    });
  }
}
