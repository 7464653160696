import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="share--facebook"
export default class extends Controller {
  connect() {
    const fjs = document.getElementsByTagName('script')[0]

    if (!document.getElementById('facebook-jssdk')) {
      const js = document.createElement('script')
      js.id = 'facebook-jssdk'
      js.src = '//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0'

      fjs.parentNode.insertBefore(js, fjs)
    }
  }
}
