import { Controller } from "@hotwired/stimulus"
import 'jquery-datetimepicker'

// Connects to data-controller="datepicker"
export default class extends Controller {
  static targets = ["form", "input"]

  connect() {
    $(this.inputTarget).datetimepicker({
      timepicker: false,
      format: 'Y-m-d',
      onChangeDateTime: () => { this.onChange(this.formTarget) }
    })
  }

  onChange(form) {
    form.action += `/${this.inputTarget.value}`
    window.location.assign(form.action)
  }
}
