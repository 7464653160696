import { Controller } from "@hotwired/stimulus"
import 'jquery-datetimepicker'

// Connects to data-controller="survey--date-picker"
export default class extends Controller {
  static targets = ["input"]
  static values = {
    min: String,
    max: String
  }

  connect() {
    $(this.inputTarget).datetimepicker({
      format:     'd-m-Y',
      timepicker: false,
      yearStart:  this.minValue,
      yearEnd:    this.maxValue
    });
  }
}
