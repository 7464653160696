import { Application } from '@hotwired/stimulus'
import jquery from "jquery"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

// Configure jQuery as global
window.jQuery = jquery
window.$ = jquery

export { application }
