import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="share--twitter"
export default class extends Controller {
  connect() {
    const fjs = document.getElementsByTagName('script')[0]
    const p = /^http:/.test(document.location) ? 'http' : 'https'

    if (!document.getElementById('twitter-wjs')) {
      const js = document.createElement('script')
      js.id = 'twitter-wjs'
      js.src = p + '://platform.twitter.com/widgets.js'

      fjs.parentNode.insertBefore(js, fjs)
    }
  }
}
