import { Controller } from "@hotwired/stimulus"
import 'jquery-datetimepicker'

// Connects to data-controller="survey--date-range-picker"
export default class extends Controller {
  static targets = ["start", "end"]
  static values = {
    min: String,
    max: String
  }

  connect() {
    $(this.startTarget).datetimepicker({
      format:     'd-m-Y',
      timepicker: false,
      yearStart:  this.minValue,
      yearEnd:    this.maxValue,
      onChangeDateTime: () => { this.setDate(this.startTarget, this.endTarget, "min") }
    });
    $(this.endTarget).datetimepicker({
      format:     'd-m-Y',
      timepicker: false,
      yearStart:  this.minValue,
      yearEnd:    this.maxValue,
      onChangeDateTime: () => { this.setDate(this.endTarget, this.startTarget, "max") }
    });
  }

  setDate(target, other_target, what) {
    var currentDate = $(target).datetimepicker("getValue");

    if (what == "min") {
      $(other_target).datetimepicker("setOptions", { minDate: currentDate })
    } else {
      $(other_target).datetimepicker("setOptions", { maxDate: currentDate })
    }

    $(other_target).datetimepicker("setOptions", { yearStart: this.minValue, yearEnd: this.maxValue })
  }
}
