import { Controller } from "@hotwired/stimulus"
import "../../vendor/jquery-ui.min"

// Connects to data-controller="survey--slider"
export default class extends Controller {
  static targets = ["slider", "input", "value"]
  static values = {
    min: Number,
    max: Number,
    granularity: Number,
    value: Number
  }

  connect() {
    $(this.sliderTarget).slider({
      range: "max",
      min: this.minValue,
      max: this.maxValue,
      step: this.granularityValue,
      value: this.valueValue,
      slide: (event, ui) => {
        $(this.inputTarget).val(ui.value);
        $(this.valueTarget).text(ui.value);
      }
    })
  }
}
