import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="survey--sub-question"
export default class extends Controller {
  static targets = ["choice", "subquestion"]

  connect() {
    const subQuestionElements = document.querySelectorAll('.sub-question')
    subQuestionElements.forEach((element) => {
      const id = element.getAttribute('id')
      const choiceId = id.replace('sub_', '')
      const choice = document.getElementById(choiceId)
      if (choice.checked !== true) {
        this.hide(element)
      }
    })
  }

  toggle_sub_question(event) {
    const element = event.target
    const callerType = element.getAttribute('type')
    const callerName = element.getAttribute('name')
    const callerId = element.getAttribute('id')
    const subQuestion = document.getElementById('sub_' + callerId)

    if (callerType === 'radio') {
      const inputElements = document.querySelectorAll('input[name=\'' + callerName + '\']')
      inputElements.forEach((element) => {
        const otherSubQuestion = document.getElementById('sub_' + element.getAttribute('id'))
        this.hide(otherSubQuestion)
      })
      this.show(subQuestion)
    }
  }

  hide(element) {
    if (element) {
      element.classList.add('d-none')
      const path = '#' + element.getAttribute('id') + ' > div > div > fieldset input.save'
      const flag = document.querySelector(path)
      flag.setAttribute('value', '')
    }
  }

  show(element) {
    if (element) {
      element.classList.remove('d-none')
      const path = '#' + element.getAttribute('id') + ' > div > div > fieldset input.save'
      const flag = document.querySelector(path)
      flag.setAttribute('value', '1')
    }
  }
}
